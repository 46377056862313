import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const TotalPrice = ({lineItem}: {lineItem: LineItemModel}) => {
  return (
    <div
      className={s.totalPrice}
      data-hook={CartItemDataHook.TotalPrice}
      data-wix-item-total-price={CartItemDataHook.TotalPrice}>
      <div className={s.totalPriceText}>{lineItem.lineItemPrice.formattedConvertedAmount}</div>
    </div>
  );
};
