import React from 'react';
import s from './SecureCheckout.scss';
import {classes} from './SecureCheckout.st.css';
import Lock from '../../../assets/images/Lock.svg';
import classNames from 'classnames';

export enum SecureCheckoutDataHook {
  lock = 'SecureCheckoutDataHook.lock',
  text = 'SecureCheckoutDataHook.text',
  root = 'SecureCheckoutDataHook.root',
}
export const SECURE_CHECKOUT_ID = 'secure-checkout-id';

export const SecureCheckout = ({text, className}: {text: string; className?: string}) => {
  return (
    <p
      id={SECURE_CHECKOUT_ID}
      className={classNames(s.secureCheckout, classes.secureCheckout, className)}
      data-hook={SecureCheckoutDataHook.root}>
      <Lock className={s.secureCheckoutLock} data-hook={SecureCheckoutDataHook.lock} />
      <span className={s.secureCheckoutText} data-hook={SecureCheckoutDataHook.text}>
        {text}
      </span>
    </p>
  );
};
