import React from 'react';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import s from './Violations.scss';
import {ViolationNotification} from './ViolationNotification/ViolationNotification';

export enum ViolationsDataHook {
  Root = 'ViolationsDataHook.Root',
}

export const Violations = () => {
  const {
    cartStore: {topThreeViolations},
  } = useControllerProps();

  return (
    topThreeViolations.length > 0 && (
      <div data-hook={ViolationsDataHook.Root} className={s.root}>
        {topThreeViolations.map((violation, index) => {
          return (
            <div key={index} className={s.notification}>
              <ViolationNotification violation={violation} />
            </div>
          );
        })}
      </div>
    )
  );
};
