import React from 'react';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../../domain/controllers/ControllerContext';

export const ExpressButtons = ({dataHook}: {dataHook: string}) => {
  const {dynamicPaymentOptionsProps} = useControllerProps().cashierExpressStore;
  const {estimatedTotals} = useControllerProps().cartStore;

  const shouldRequestShipping = dynamicPaymentOptionsProps?.shouldRequestShipping;
  const canCheckout = dynamicPaymentOptionsProps?.canCheckout;

  return (
    <ExpressCheckoutButtons
      layout={'vertical'}
      isShippable={shouldRequestShipping}
      estimatedTotal={estimatedTotals?.estimatedAmountToPay.toString()}
      canStartPayment={canCheckout}
      theme={dynamicPaymentOptionsProps?.getButtonTheme}
      buttonStyle={dynamicPaymentOptionsProps?.buttonStyle}
      dataHook={dataHook}
    />
  );
};
