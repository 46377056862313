import React from 'react';

interface SkeletonProps {
  children: React.ReactNode;
  className?: string;
  dataHook?: string;
}

export const Skeleton = ({children, className, dataHook}: SkeletonProps) => (
  <div data-hook={dataHook} className={className}>
    {children}
  </div>
);
