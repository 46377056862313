import React from 'react';
import settingsParams from '../../../settingsParams';
import {Text, TextPriority, IconButton, Divider} from 'wix-ui-tpa/cssVars';
import {Close} from '@wix/wix-ui-icons-common/on-stage';
import {classes} from './Header.st.css';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import stylesParams from '../../../stylesParams';
import classNames from 'classnames';
import {useBooleanStyleForAnyBreakpoint} from '../../../../../common/useBooleanStyleForAnyBreakpoint';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum HeaderDataHooks {
  title = 'Header.Title',
  itemsCountText = 'Header.itemsCountText',
  closeButton = 'Header.closeButton',
}

export interface HeaderProps {
  closeButtonRef: React.RefObject<HTMLButtonElement>;
}

export const Header = ({closeButtonRef}: HeaderProps) => {
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {getBooleanStyle} = useBooleanStyleForAnyBreakpoint();
  const {itemsCount, sideCartElementsVisibilityInCss} = useControllerProps().cartStore;
  const {closeSideCart} = useControllerProps().navigationStore;
  const {t} = useTranslation();
  const showTitle = getBooleanStyle(stylesParams.CART_TITLE_VISIBILITY, sideCartElementsVisibilityInCss);
  const showItemsCount = getBooleanStyle(
    stylesParams.CART_NUMBER_OF_CART_ITEMS_VISIBILITY,
    sideCartElementsVisibilityInCss
  );

  return (
    <div className={classes.headerContainer}>
      <div className={classes.header}>
        <Text priority={TextPriority.primary} className={classes.headerText}>
          {showTitle ? (
            <>
              <Text
                tagName={'h2'}
                data-hook={HeaderDataHooks.title}
                className={classNames(
                  classes.headerTitle,
                  sideCartElementsVisibilityInCss ? classes.headerTitleVisibility : undefined
                )}>
                {getSettingsWithDefaults(settingsParams.CART_TITLE)}
              </Text>{' '}
            </>
          ) : null}
          {showItemsCount ? (
            <Text
              data-hook={HeaderDataHooks.itemsCountText}
              className={classNames(
                classes.headerItemCount,
                sideCartElementsVisibilityInCss ? classes.headerItemCountVisibility : undefined
              )}>
              {t('sideCart.title.itemQuantity', {itemQuantity: itemsCount ?? 0})}
            </Text>
          ) : null}
        </Text>
        <IconButton
          innerRef={closeButtonRef}
          className={classes.closeButton}
          data-hook={HeaderDataHooks.closeButton}
          icon={<Close className={classes.closeButtonIcon} />}
          aria-label={t('sideCart.closeCart.ariaLabel')}
          onClick={() => closeSideCart()}
        />
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};
