import React from 'react';
import {Skeleton} from '../../../../../../common/components/Skeleton/Skeleton';
import {SkeletonSection} from '../../../../../../common/components/Skeleton/SkeletonSection/SkeletonSection';
import {SkeletonRow} from '../../../../../../common/components/Skeleton/SkeletonRow/SkeletonRow';
import {SkeletonLineWrapper} from '../../../../../../common/components/Skeleton/SkeletonLine/SkeletonLineWrapper';
import {classes} from './FooterSkeleton.st.css';
import {FooterDataHooks} from '../Footer';

export const FooterSkeleton = () => (
  <Skeleton dataHook={FooterDataHooks.skeleton} className={classes.footerSkeleton}>
    <SkeletonSection condensed={true}>
      <SkeletonRow>
        <SkeletonLineWrapper size="medium" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="xl" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="xl" />
      </SkeletonRow>
    </SkeletonSection>
  </Skeleton>
);
