import React from 'react';
import {classes, st} from './SkeletonSection.st.css';

interface SkeletonSectionProps {
  children: React.ReactNode;
  condensed?: boolean;
}

export const SkeletonSection = ({children, condensed}: SkeletonSectionProps) => (
  <div className={st(classes.section, condensed ? classes.condensed : undefined)}>{children}</div>
);
