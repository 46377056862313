import React from 'react';
import s from './GeneralError.scss';

export enum GeneralErrorDataHooks {
  Root = 'GeneralErrorDataHooks.Root',
}

export const GeneralError = () => {
  return (
    <div className={s.generalError} data-hook={GeneralErrorDataHooks.Root}>
      <h1>Something went wrong while loading your cart</h1>
      <p>Please try again in a few minutes</p>
    </div>
  );
};
