import {ISettingsParam} from '@wix/tpa-settings';
import {useSettings} from '@wix/tpa-settings/react';

export const useSettingsWithDefaults = () => {
  const {get: getSettings, getDefaultValue} = useSettings();

  return {
    getSettingsWithDefaults: (settingKey: ISettingsParam): string =>
      (getSettings(settingKey) || getDefaultValue(settingKey)) as string,
  };
};
