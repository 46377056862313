import React, {useEffect} from 'react';
import {useControllerProps} from '../../../domain/controllers/ControllerContext';
import {CartItem} from './CartItem/CartItem';

export enum CartItemsDataHook {
  'root' = 'CartItemsDataHook.root',
  'item' = 'CartItemsDataHook.item',
}
interface CartItemsProps {
  showQuantityBySettings: boolean;
  showThumbnailBySettings: boolean;
  shouldShowItemInfoBySettings: boolean;
  hideLastDivider?: boolean;
  onRemoveItem?: () => void;
  quantityClassName?: string;
  thumbnailClassName?: string;
  infoClassName?: string;
}
export const CartItems: React.FC<CartItemsProps> = ({
  showQuantityBySettings,
  showThumbnailBySettings,
  shouldShowItemInfoBySettings,
  hideLastDivider = false,
  onRemoveItem,
  quantityClassName,
  thumbnailClassName,
  infoClassName,
}) => {
  const {cartModel, lineItemsLoaded} = useControllerProps().cartStore;

  useEffect(
    () => {
      lineItemsLoaded();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  return (
    <div data-hook={CartItemsDataHook.root}>
      <ul>
        {cartModel.lineItems.map((lineItem, index) => (
          <li key={`cart-item-${lineItem.id}`} data-hook={CartItemsDataHook.item} data-wix-line-item-id={lineItem.id}>
            <CartItem
              showDivider={!hideLastDivider || index !== cartModel.lineItems.length - 1}
              lineItem={lineItem}
              showQuantityBySettings={showQuantityBySettings}
              showThumbnailBySettings={showThumbnailBySettings}
              shouldShowItemInfoBySettings={shouldShowItemInfoBySettings}
              onRemoveItem={onRemoveItem}
              quantityClassName={quantityClassName}
              thumbnailClassName={thumbnailClassName}
              infoClassName={infoClassName}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
