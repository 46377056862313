import {resizeMediaByWidth} from '@wix/wixstores-client-core';
import {LineItemModel} from '../models/LineItem.model';

type MediaDimensions = {width: number; height: number};

export const getImageDimensions = (lineItem: LineItemModel, defaultDimensions: MediaDimensions): MediaDimensions => {
  const primaryImage = lineItem.image;

  if (primaryImage) {
    return resizeMediaByWidth(primaryImage, 100);
  }

  return defaultDimensions;
};
