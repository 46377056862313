export function debounce<T extends (...args: any[]) => any>(fn: T, time: number = 300) {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<T>): Promise<ReturnType<T>> => {
    return new Promise((resolve, reject) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        try {
          const res = fn(...args);
          resolve(res);
        } catch (e) {
          /* istanbul ignore next */
          reject(e);
        }
      }, time);
    });
  };
}
