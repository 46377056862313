import {useStyles} from '@wix/tpa-settings/react';
import {IStyleParam} from '@wix/tpa-settings/src/contexts/StylesContext/StylesContext.types';
import {StyleParamType} from '@wix/tpa-settings';

export function useBooleanStyleForAnyBreakpoint() {
  const {get, getStylesForAllBreakpoints} = useStyles();
  return {
    getBooleanStyle: (param: IStyleParam<StyleParamType.Boolean>, supportBreakpoints: boolean) => {
      return supportBreakpoints
        ? getStylesForAllBreakpoints().some((styleParams) => {
            return styleParams.booleans[param.key] ?? param.getDefaultValue(undefined);
          })
        : get(param);
    },
  };
}
