import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {ItemLink} from '../ItemLink/ItemLink';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Name = ({lineItem}: {lineItem: LineItemModel}) => {
  const {productName} = lineItem;

  return (
    <p data-hook={CartItemDataHook.Name} className={s.productName}>
      <ItemLink lineItem={lineItem}>{productName}</ItemLink>
    </p>
  );
};
