import React from 'react';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Discounts = ({lineItem}: {lineItem: LineItemModel}) => {
  return (
    <div data-hook={CartItemDataHook.Discounts}>
      {lineItem.discountRules?.map(({name}, index: number) => (
        <div
          key={`${CartItemDataHook.DiscountName}-${index}`}
          data-hook={`${CartItemDataHook.DiscountName}-${index}`}
          className={s.discountName}>
          {name}
        </div>
      ))}
    </div>
  );
};
