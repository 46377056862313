import React from 'react';
import s from '../../CartItem.scss';
import {CartItemDataHook} from '../../CartItem';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Price = ({lineItem}: {lineItem: LineItemModel}) => {
  return (
    <div className={s.prices}>
      {lineItem.crossedOutPrice?.formattedConvertedAmount && (
        <span
          data-hook={CartItemDataHook.ComparePrice}
          data-wix-line-full-item-price={CartItemDataHook.ComparePrice}
          className={s.comparePrice}>
          {lineItem.crossedOutPrice.formattedConvertedAmount}
        </span>
      )}
      {lineItem.price.formattedConvertedAmount && (
        <span data-hook={CartItemDataHook.Price} className={s.price} data-wix-line-item-price={CartItemDataHook.Price}>
          {lineItem.price.formattedConvertedAmount}
        </span>
      )}
    </div>
  );
};
