import React from 'react';
import {getHref} from '../../../../../../domain/utils/itemUtils';
import {useControllerProps, UseControllerPropsResult} from '../../../../../../domain/controllers/ControllerContext';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export enum ItemLinkDataHooks {
  Anchor = 'ItemLinkDataHooks.Anchor',
  NonClickableItem = 'ItemLinkDataHooks.NonClickableItem',
}

const handleClick = ({
  event,
  navigationStore,
  fullUrl,
  urlPart,
}: {
  event: React.MouseEvent<HTMLAnchorElement>;
  navigationStore: UseControllerPropsResult['navigationStore'];
  fullUrl: string;
  urlPart?: string;
}) => {
  event.preventDefault();
  event.stopPropagation();
  if (fullUrl) {
    return navigationStore.navigateToFullProductUrl(fullUrl);
  }
  return navigationStore.navigateToProduct(urlPart);
};

export const ItemLink = ({
  lineItem,
  children,
  tabIndex,
}: {
  lineItem: LineItemModel;
  children: React.ReactChild;
  tabIndex?: number;
}) => {
  const {cartStore, navigationStore} = useControllerProps();
  const href = getHref(cartStore, lineItem);
  const shouldRenderNonClickableItem = Boolean(
    (!lineItem.url.relativePath || lineItem.url.relativePath === '') && !lineItem.url.url
  );

  return shouldRenderNonClickableItem ? (
    <div data-hook={ItemLinkDataHooks.NonClickableItem}>{children}</div>
  ) : (
    <a
      href={href}
      onClick={(event) =>
        handleClick({event, navigationStore, fullUrl: lineItem.url.url, urlPart: lineItem.url.relativePath})
      }
      {...(tabIndex !== undefined ? {tabIndex} : {})}
      data-hook={ItemLinkDataHooks.Anchor}>
      {children}
    </a>
  );
};
