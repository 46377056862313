import React from 'react';
import {classes, st} from './SkeletonLineWrapper.st.css';
import {SkeletonLine} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';

interface SkeletonLineProps {
  size: string;
  dataHook?: string;
}

export const SkeletonLineWrapper = ({size, dataHook}: SkeletonLineProps) => {
  const {useSkeletonLineFromWUT} = useControllerProps().cartStore;

  return useSkeletonLineFromWUT ? (
    <SkeletonLine data-hook={dataHook} className={classes[size]} />
  ) : (
    <div data-hook={dataHook} className={st(classes.skeletonLine, classes[`${size}-old`])}></div>
  );
};
