import React from 'react';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {classes} from './Body.st.css';
import {CartItems} from '../../../../../common/components/CartItems/CartItems';
import {Text} from 'wix-ui-tpa/cssVars';
import settingsParams from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import {useBooleanStyleForAnyBreakpoint} from '../../../../../common/useBooleanStyleForAnyBreakpoint';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';

export enum BodyDataHooks {
  emptyState = 'BodyDataHooks.emptyState',
}

export interface BodyProps {
  onRemoveItem: () => void;
}

export const Body = ({onRemoveItem}: BodyProps) => {
  const {shouldRenderEmptyState, sideCartElementsVisibilityInCss} = useControllerProps().cartStore;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {getBooleanStyle} = useBooleanStyleForAnyBreakpoint();
  const showQuantityBySettings = getBooleanStyle(
    stylesParams.CART_ITEM_QUANTITY_COUNTER_VISIBILITY,
    sideCartElementsVisibilityInCss
  );
  const showThumbnailBySettings = getBooleanStyle(
    stylesParams.CART_ITEM_IMAGE_VISIBILITY,
    sideCartElementsVisibilityInCss
  );
  const shouldShowItemInfoBySettings = getBooleanStyle(stylesParams.CART_ITEM_INFO, sideCartElementsVisibilityInCss);

  return (
    <div>
      {shouldRenderEmptyState ? (
        <div className={classes.emptyState}>
          <Text data-hook={BodyDataHooks.emptyState} className={classes.emptyStateText}>
            {getSettingsWithDefaults(settingsParams.CART_EMPTY_CART)}
          </Text>
        </div>
      ) : (
        <CartItems
          onRemoveItem={onRemoveItem}
          hideLastDivider={true}
          showQuantityBySettings={showQuantityBySettings}
          showThumbnailBySettings={showThumbnailBySettings}
          shouldShowItemInfoBySettings={shouldShowItemInfoBySettings}
          quantityClassName={sideCartElementsVisibilityInCss ? classes.quantityVisibility : undefined}
          thumbnailClassName={sideCartElementsVisibilityInCss ? classes.imageVisibility : undefined}
          infoClassName={sideCartElementsVisibilityInCss ? classes.infoVisibility : undefined}
        />
      )}
    </div>
  );
};
