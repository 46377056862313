import React from 'react';
import {WidgetProps, useEnvironment} from '@wix/yoshi-flow-editor';
import {IControllerProps} from '../../../types/app.types';
import {GeneralError} from '../../../common/components/GeneralError/GeneralError';
import {ControllerContext} from '../../../domain/controllers/ControllerContext';
import {SideCart} from './SideCart';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';
import {withExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';

const SideCartAppRoot = (controllerProps: WidgetProps<IControllerProps>) => {
  const {isRTL} = useEnvironment();

  if (controllerProps.generalError) {
    return <GeneralError />;
  }

  return (
    <ControllerContext value={controllerProps}>
      <TPAComponentsProvider value={{rtl: isRTL}}>
        <SideCart dir={isRTL ? 'rtl' : 'ltr'} />
      </TPAComponentsProvider>
    </ControllerContext>
  );
};

export default withExpressCheckoutButtons(SideCartAppRoot);
